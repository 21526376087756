import {
  Box,
  Button,
  ButtonProps,
  Image,
  Link,
  Spacer,
  Stack,
} from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { useGetLocalizedMarketingSiteUrls } from 'modules/marketing_pages/hooks/useGetLocalizedMarketingSiteLinks'
import { useUserContext } from 'modules/user'
import gammaLogo from 'publicImages/gamma-logo-white.svg'
import { isMobileDevice } from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'

import { MARKETING_HEADER_HEIGHT, MARKETING_SITE_LINKS } from '../constants'

export type HeaderMode = 'dark' | 'light'

type PrimaryButtonProps = {
  children: React.ReactNode
  href: string
  buttonProps?: ButtonProps
}
const PrimaryButton = ({ children, href, buttonProps }: PrimaryButtonProps) => {
  return (
    <Button
      as={Link}
      href={href}
      maxHeight="32px"
      borderStyle="solid"
      colorScheme="gray"
      borderWidth="0.5px"
      fontSize="14px"
      borderColor="rgba(0, 0, 0, 0.24)"
      backgroundColor="#fff"
      boxShadow="0 4px 4px 0 rgb(0 0 0 / 5%)"
      fontWeight="700"
      borderRadius="100px"
      color="#3b3a40"
      _hover={{
        color: 'black',
        textDecoration: 'none',
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

const LoggedInNavItems = () => {
  return (
    <PrimaryButton
      href="/"
      buttonProps={{ rightIcon: <FontAwesomeIcon icon={faArrowRight} /> }}
    >
      <Trans>Go to app</Trans>
    </PrimaryButton>
  )
}
const LoggedOutNavItems = ({ isLight }: { isLight?: boolean }) => {
  const { pricingUrl } = useGetLocalizedMarketingSiteUrls()

  const HEADER_LINKS = [
    {
      text: <Trans>Pricing</Trans>,
      url: pricingUrl,
      hideWhenSmall: true,
    },
    {
      text: <Trans>Careers</Trans>,
      url: MARKETING_SITE_LINKS.careers,
      hideWhenSmall: true,
    },
    {
      text: <Trans>Login</Trans>,
      url: MARKETING_SITE_LINKS.login,
    },
  ]

  return (
    <>
      {HEADER_LINKS.map(({ text, url, hideWhenSmall }, ind) => {
        return (
          <Link
            as={NextLink}
            key={ind}
            display={hideWhenSmall ? ['none', 'none', 'block'] : 'block'}
            href={url}
            color={isLight ? undefined : 'whiteAlpha.800'}
            _hover={{
              color: isLight ? '#000' : 'whiteAlpha.900',
              textDecoration: 'none',
            }}
          >
            {text}
          </Link>
        )
      })}
      <PrimaryButton href={MARKETING_SITE_LINKS.signup}>
        <Trans>Try for free</Trans>
      </PrimaryButton>
    </>
  )
}
export const MarketingHeader = ({
  headerMode = 'light',
  fixed = false,
}: {
  headerMode?: HeaderMode
  fixed?: boolean
}) => {
  const { homeUrl } = useGetLocalizedMarketingSiteUrls()
  const { user, isUserLoading } = useUserContext()
  const isLight = headerMode === 'light'
  const showLoggedInNav = user && !isUserLoading
  const shouldUseFixedHeader = fixed && !isMobileDevice()

  const ssrMounted = useSSRMounted()
  return (
    <Box
      w="100%"
      py={3}
      px={[6, 6, 12]}
      maxH={MARKETING_HEADER_HEIGHT}
      pr={[4, 6, 12]}
      zIndex="999"
      top={['auto', 'auto', '0']}
      position={shouldUseFixedHeader ? 'fixed' : 'relative'}
      backdropFilter={shouldUseFixedHeader ? 'blur(20px)' : 'none'}
      backgroundColor={
        shouldUseFixedHeader
          ? isLight
            ? 'whiteAlpha.800'
            : 'blackAlpha.800'
          : 'transparent'
      }
    >
      <Stack direction="row">
        <Link href={homeUrl} aria-current="page" as={NextLink}>
          <Image
            w="100px"
            src={
              isLight
                ? 'https://assets.website-files.com/62e823626cd4fdd0e5dc1527/62e823626cd4fdc26bdc1576_Gamma.svg'
                : gammaLogo.src
            }
            loading="lazy"
            alt="Gamma logo"
          />
        </Link>
        <Spacer />

        {ssrMounted && (
          <Stack
            direction="row"
            spacing={[4, 4, 8]}
            fontWeight="500"
            fontSize="14px"
            color={isLight ? '#3b3a40' : '#fff'}
            alignItems="center"
            transition="color 300ms ease"
          >
            {!user && isUserLoading ? (
              <></>
            ) : showLoggedInNav ? (
              <LoggedInNavItems />
            ) : (
              <LoggedOutNavItems isLight={isLight} />
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
