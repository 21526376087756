export const MARKETING_CONTAINER_MAX_WIDTH = '940px'

export const MARKETING_SITE_LINKS = {
  pricing: '/pricing',
  help: 'https://help.gamma.app',
  changeLog: 'https://ideas.gamma.app/changelog',
  team: 'https://careers.gamma.app/team',
  terms: 'https://gamma.app/terms',
  privacy: 'https://gamma.app/privacy',
  login: '/signin',
  signup: '/signup',
  templates: '/templates',
  inspiration: '/inspiration',
  careers: 'https://careers.gamma.app',
}

export const MARKETING_HEADER_HEIGHT = 16
