import { Flex } from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { faLanguage as faLanguageSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { LocalePicker } from 'modules/i18n/components/LocalePicker'
import { SUPPORTED_LOCALE_OPTIONS } from 'modules/i18n/constants'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { useGetLocalizedUrlForThisPageIfAvailable } from 'modules/marketing_pages/hooks/useGetLocalizedUrlForThisPageIfAvailable'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

export const MarketingLanguageSwitcher = () => {
  const getUrl = useGetLocalizedUrlForThisPageIfAvailable()
  const { replace } = useRouter()
  // The latest known value of your user setting locale
  const [_lsLocale, setLocalStorageLocale] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.gammaLocale,
    null
  )
  const localeKey = useLinguiLocale()

  const handleLocaleClick = useCallback(
    (localeClicked) => {
      const path = getUrl(localeClicked)
      if (path) {
        // No need to set local storage locale here; we will set it in useLinguiInit
        replace(path, undefined, { shallow: false, scroll: false })
      } else {
        setLocalStorageLocale(localeClicked)
        window.location.reload()
      }
    },
    [getUrl, replace, setLocalStorageLocale]
  )

  return (
    <Flex justify="flex-end">
      <LocalePicker
        matchWidth={false}
        selectedLocale={SUPPORTED_LOCALE_OPTIONS[localeKey]}
        onLocaleSelect={(lkey) => {
          handleLocaleClick(lkey)
        }}
        buttonProps={{
          variant: 'solid',
          colorScheme: 'gray',
          rightIcon: <FontAwesomeIcon size="xs" icon={faChevronDown} />,
          leftIcon: <FontAwesomeIcon size="1x" icon={faLanguageSolid} />,
        }}
        menuListProps={{
          fontSize: 'sm',
          minW: '300px',
          fontWeight: '700',
        }}
      />
    </Flex>
  )
}
